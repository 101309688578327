import React, { useState, useEffect } from 'react';

const VoiceCommand = () => {
    const [text, setText] = useState('');
    const [error, setError] = useState(null);
    const [isListening, setIsListening] = useState(true); // Start listening by default
    const [recognition, setRecognition] = useState(null);
    const [emailPopup, setEmailPopup] = useState(false);
    const [copiedEmail, setCopiedEmail] = useState(false);

    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            setError('Your browser does not support Speech Recognition.');
            return;
        }

        const recognitionInstance = new SpeechRecognition();
        recognitionInstance.continuous = true;
        recognitionInstance.interimResults = true;
        recognitionInstance.lang = 'en-US';

        recognitionInstance.onstart = () => {
            console.log('Speech recognition started');
            setIsListening(true);
        };

        recognitionInstance.onresult = (event) => {
            const transcript = Array.from(event.results)
                .map(result => result[0])
                .map(result => result.transcript)
                .join('');
            setText(transcript);
            handleCommand(transcript);
        };

        recognitionInstance.onend = () => {
            console.log('Speech recognition ended');
            setIsListening(false);
        };

        recognitionInstance.onerror = (event) => {
            console.error('Speech recognition error:', event.error);
            setIsListening(false);
        };

        setRecognition(recognitionInstance);

        // Start listening automatically
        recognitionInstance.start();

        return () => {
            recognitionInstance.stop();
        };
    }, []);

    const handleCommand = (command) => {
        // Clear the result
        setText('');
        window.openedWhatsApp = false;
        setEmailPopup(false);
        
        // Handle the recognized command
        const lowerCaseCommand = command.toLowerCase();
        if (lowerCaseCommand.includes('contact on email') && !emailPopup) {
            // Display the email address with a close option
            setEmailPopup(true);
        }
        if (lowerCaseCommand.includes('contact on whatsapp')) {
            // Open WhatsApp in a new window (only once)
            if (!window.openedWhatsApp) {
                window.open('https://wa.me/13473303183','_blank');
                window.openedWhatsApp = true;
            }
        }
    };

    const closeEmailPopup = () => {
        setEmailPopup(false);
        setText('');
    };

    const copyEmailToClipboard = () => {
        const emailInput = document.createElement('input');
        emailInput.value = 'dev.bhanuteja@gmail.com';
        document.body.appendChild(emailInput);
        emailInput.select();
        document.execCommand('copy');
        document.body.removeChild(emailInput);
        setCopiedEmail(true);
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="bg-indigo-900 text-center py-4 lg:px-4">
            <div className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                    {isListening ? 'Listening' : 'Stopped'}
                </span>
                <span className="font-semibold mr-2 text-left flex-auto">
                    Voice Command
                </span>
                <button
                    onClick={() => recognition.start()}
                    disabled={isListening}
                    className={`text-xs font-bold mr-2 cursor-pointer ${isListening ? 'opacity-50 cursor-not-allowed' : 'text-indigo-200 hover:text-white'}`}
                >
                    Start
                </button>
                <button
                    onClick={() => recognition.stop()}
                    disabled={!isListening}
                    className={`text-xs font-bold cursor-pointer ${!isListening ? 'opacity-50 cursor-not-allowed' : 'text-indigo-200 hover:text-white'}`}
                >
                    Stop
                </button>
            </div>
            {emailPopup && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="md:w-80 max-w-xs rounded shadow-lg p-6 dark:bg-gray-800 bg-white relative">
                        <button
                            onClick={closeEmailPopup}
                            className="absolute top-2 right-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white cursor-pointer"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <h1 className="dark:text-gray-100 text-gray-800 font-bold text-lg">
                            Command Response
                        </h1>
                        <p className="pb-4 text-sm pt-4 font-normal dark:text-gray-100 text-gray-600">
                            Contact on my email
                        </p>
                        <div className="w-full bg-gray-100 rounded-lg flex items-center justify-between p-4">
                            <p className="text-base font-medium leading-6 dark:text-black text-black">
                                dev.bhanuteja@gmail.com
                            </p>
                            <div
                                onClick={copyEmailToClipboard}
                                className={`cursor-pointer text-xs font-semibold leading-4 ${copiedEmail ? 'text-green-500' : 'text-indigo-700'} uppercase`}
                            >
                                {copiedEmail ? 'COPIED' : 'COPY'}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <p className="text-indigo-100 text-center mt-2">{text}</p>
        </div>
    );
};

export default VoiceCommand;
