import React from 'react'
import MyImage from './images/image0.jpeg'
import VoiceCommand from './components/VoiceCommand'
function App() {
  return (
    <div className="bg-gray-100 ">
     <VoiceCommand />
    <div className="mx-auto container relative px-6 xl:px-0">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 items-center pb-40">
            <div className="md:block xl:mt-8">
                <div className="text-4xl  text-gray-800 tracking-1px font-semibold leading-10">
                    <div>
                        <h3 className="mt-2 lg:mt-0">Hello,</h3>
                    </div>
                    <h2 className="mt-2 lg:mt-0 text-indigo-700">I'm Bhanu Teja</h2>
                    <h3 className="mt-2 lg:mt-0">Python & AI Developer</h3>
                </div>
                <h2 className="text-lg lg:text-2xl tracking-wide leading-9 lg:w-10/12 mt-2 lg:mt-6 text-gray-700">A Python & AI Developer, passionate about harnessing the power of artificial intelligence to create innovative solutions</h2>
            </div>
         
            <div className=" md:block w-full custom-height bg-indigo-100 mt-8 lg:mt-0 rounded-3xl relative">
                <img className="w-full" src={MyImage} alt="Animated Man standing with a flag"  />
            </div>
           
        </div>
    </div>
    <div>
           
        </div>
   
    
</div>

  )
}

export default App
